@font-face {
  font-family: "Polly_Rounded_Bold";
  src: local("Polly_Rounded_Bold"), url("../fonts/PollyRounded-Bold.otf") format("opentype"),
    url("../fonts/PollyRounded-Bold.ttf") format("truetype"), url("../fonts/PollyRounded-Bold.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "PollyRounded-Light";
  src: local("PollyRounded-Light"), url("../fonts/PollyRounded-Light.otf") format("opentype"),
    url("../fonts/PollyRounded-Light.ttf") format("truetype"), url("../fonts/PollyRounded-Light.woff") format("woff");
  font-weight: lighter;
}
@font-face {
  font-family: "PollyRounded-Regular";
  src: local("PollyRounded-Regular"), url("../fonts/PollyRounded-Regular.otf") format("opentype"),
    url("../fonts/PollyRounded-Regular.ttf") format("truetype"), url("../fonts/PollyRounded-Regular.woff") format("woff");
  font-weight: lighter;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 1rem;
}
* {
  scrollbar-width: thin;
  transition: all 0.3s ease-out 0.1s;
}
html,
body {
  font-size: 16px;
}
a {
  color: #834a4b;
  font-family: "PollyRounded-Regular";
}
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
div,
select,
input,
textarea {
  font-family: "PollyRounded-Regular" !important;
}
p {
  font-size: 0.9rem;
}
h3,
.h3 {
  font-size: 22px !important;
}
#header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}
.mainContent {
  padding-top: 60px;
}
.wtk_heading {
  color: #a75758 !important;
}
.mainNav li a {
  font-size: 14px;
  font-family: "Polly_Rounded_Bold";
}
.mainNav li a.loginBtn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #a75758;
  color: white !important;
  border-radius: 5rem;
}
.mainNav li a.loginBtn:hover,
.mainNav li a.loginBtn:focus {
  background-color: #834a4b !important;
}
/* .text-primary,
.text-primary:hover {
  color: #834a4b !important;
} */
.wktNav.navbar-collapse.collapse.in {
  position: absolute;
  display: flex !important;
  z-index: 1000;
  background: white;
  width: 100%;
  height: auto !important;
  overflow: visible;
}
.section-frontend #weddingTK.weddingTK section {
  margin-left: auto;
  margin-right: auto;
}
.section-frontend #weddingTK.weddingTK .heroBG {
  background: url(../wkt/banner.png) no-repeat center / cover;
  padding: 5rem 0;
}
.pollyBold {
  font-family: "Polly_Rounded_Bold" !important;
}
.section-frontend .pollyRegular {
  font-family: "PollyRounded-Regular" !important;
}
.section-frontend #weddingTK.weddingTK .mainHeading {
  font-size: 48px;
  line-height: 1.5;
  letter-spacing: 0.6;
}
.section-frontend #weddingTK.weddingTK .text4xl {
  font-size: 4rem;
}
.textWhite {
  color: white;
}
.p0F {
  padding: 0 !important;
}
.section-frontend #weddingTK.weddingTK .py-5r {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.section-frontend #weddingTK.weddingTK .pb-5r {
  padding-bottom: 5rem;
}
.section-frontend #weddingTK.weddingTK .py-2r {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.section-frontend #weddingTK.weddingTK .mt0 {
  margin-top: 0;
}
.section-frontend #weddingTK.weddingTK .mt1r {
  margin-top: 1rem;
}
.section-frontend #weddingTK.weddingTK .mt2r {
  margin-top: 2rem;
}
.section-frontend #weddingTK.weddingTK .mb1r {
  margin-bottom: 1rem;
}
.section-frontend #weddingTK.weddingTK .mb0 {
  margin-bottom: 0;
}
.section-frontend #weddingTK.weddingTK .mb2r {
  margin-bottom: 2rem;
}
.section-frontend #weddingTK.weddingTK .hfull {
  height: 100%;
}
.flex {
  display: flex;
}
.itemCenter {
  align-items: center;
}
.gap10p {
  gap: 10px;
}
.gap10p {
  gap: 10px;
}
.imageFilter {
  filter: invert(86%) sepia(99%) saturate(267%) hue-rotate(353deg) brightness(82%) contrast(82%);
}
.primary_button {
  background-color: #c3924f;
}
.primary_gradient {
  background-color: linear-gradient(135deg, #a75758 0%, #dfbbbd 100%);
}
/* BORDER  HOVER  EFFECTS */
@-webkit-keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.rainbow {
  position: relative;
  z-index: 0;
  border-radius: 5rem 5px 5rem 5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start;
}
.rainbow h4 {
  font-size: 24px !important;
  margin: 15px 0 !important;
}
.rainbow::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: #e6b893;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  background-position: 0 0;
  background-image: linear-gradient(#e6b893, #d88f55);
  -webkit-animation: rotate 8s linear infinite;
  animation: rotate 8s linear infinite;
}
.rainbow::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: white;
  border-radius: 5rem 5px 5rem 5rem;
}
.sharingItem {
  /* border: 3px solid #e6b893; */
  border-radius: 5rem 5px 5rem 5rem;
  padding: 1.4rem;
  position: relative;
}
.sharingPos {
  position: absolute;
  bottom: 5px;
  right: 3rem;
}
.QRCode {
  width: 233px;
  min-width: 233px;
  height: 233px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6rem 6px 6rem 6rem;
}
.section-frontend #weddingTK.weddingTK .weddingStory {
  background-image: url(../wkt/backimage.png), url(../wkt/flower.svg);
  background-repeat: no-repeat, no-repeat;
  background-size: 400px, 200px;
  background-position: top left, 95% 40%;
  border-bottom: 1px solid #e7ab9d;
}
.section-frontend #weddingTK.weddingTK .weddingStory h3 {
  font-size: 28px;
  margin: 20px 0 10px;
}
.videoPlayer {
  background-color: #e9f3fb;
}
.parent {
  background-color: green;
  padding: 10px;
}
.section-frontend #weddingTK.weddingTK .features {
  background: url(../wkt/flower.svg) no-repeat left top 20%/ 120px;
}
.features .row {
  display: flex;
  flex-wrap: wrap;
}
.features .featureItem {
  background: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  border-radius: 5rem 5px 2rem 2rem;
  height: 100%;
  overflow: hidden;
}
.features .featureItem figure > img {
  border-radius: 5rem 0 0 0;
}
.features .featureItem:hover figure > img {
  transform: scale(1.1);
}
.features .featureItem figure figcaption {
  padding: 1rem;
  text-align: center;
}
.features .featureItem .captionIcon {
  width: 80px;
  height: 80px;
  border-radius: 5rem;
  background-color: #e9cbca;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: -3.5rem;
  position: relative;
  z-index: 10;
}
.playingBanner {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.4s ease;
}
.playingBanner .playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10000;
  background-color: #e7ab9d;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
}
/* .playingBanner .vidBanner:hover {
  transform: scale(1.04);
} */
.section-frontend #weddingTK.weddingTK .wtkVideo {
  background: #dfbbbd url(../wkt/logoSMopac.png) no-repeat center / auto 92%;
}
.section-frontend #weddingTK.weddingTK .applications {
  background-color: #f8eeee;
  /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem; */
  padding: 5rem 2rem;
}
.section-frontend #weddingTK.weddingTK .coupleSaying {
  background: #f8eeee;
}
.sliderSectionWrapper {
  padding-bottom: 5rem;
  position: relative;
  min-height: 440px;
}
.sliderSection {
  min-width: 780px;
  min-height: 380px;
  background: url(../wkt/lines.svg) center;
  background-size: contain;
  background-repeat: repeat-x;
  width: 100%;
  animation: wave 80s infinite linear alternate;
  overflow: hidden;
}
@keyframes wave {
  0% {
    background-position: center center;
  }
  100% {
    background-position: -96.75rem center;
    /* margin-left: -96.75rem; */
  }
}
.anim {
  position: absolute;
  background: url(../wkt/lines.svg) center/contain repeat-x;
  top: 0;
  width: 378rem;
  height: 12rem;
  min-height: 360px;
  animation: wave 60s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  overflow-x: hidden;
  overflow-y: visible;
}
.testimonial {
  position: absolute;
  min-width: 360px;
  padding: 0.75rem 1.25rem;
  background: white;
  border: 1px solid #a75758;
  right: 1.5rem;
  z-index: 1;
  border-radius: 2rem 0.2rem 2rem 2rem;
  margin-top: -1.5rem;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  padding-top: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.sliderSeven .testimonial {
  right: auto;
  left: 1.5rem;
  border-radius: 0.2rem 2rem 2rem 2rem;
}
.sliderEight .testimonial {
  right: auto;
  left: 1.5rem;
  border-radius: 0.2rem 2rem 2rem 2rem;
}
.sliderNine .testimonial {
  right: auto;
  left: 1.5rem;
  border-radius: 0.2rem 2rem 2rem 2rem;
}
.testimonial.active {
  transform: scale(1);
  opacity: 1;
}
.testimonial h5 {
  margin-bottom: 0;
  font-family: "PollyRounded-Regular";
}
.testimonial h3 {
  margin-top: 0;
  color: #a75758;
  font-family: "Polly_Rounded_Bold";
}
.testimonialOverlay {
  position: relative;
  z-index: 100;
}
.sliderOne,
.sliderTwo,
.sliderThree,
.sliderFour,
.sliderFive,
.sliderSix,
.sliderSeven,
.sliderEight,
.sliderNine {
  position: absolute;
  cursor: pointer;
}
.sliderOne {
  top: 1rem;
  right: 5rem;
}
.sliderTwo {
  top: 18rem;
  right: 2rem;
}
.sliderThree {
  top: 12rem;
  right: 22rem;
}
.sliderFour {
  top: 17rem;
  right: 45rem;
}
.sliderFive {
  top: 4rem;
  right: 35rem;
}
.sliderSix {
  top: 3.25rem;
  left: 31.5rem;
}
.sliderSeven {
  top: 2.5rem;
  left: 11rem;
}
.sliderEight {
  top: 19rem;
  left: 18rem;
}
.sliderNine {
  top: 16rem;
  left: 2rem;
}
.downloadSection {
  display: flex;
  align-items: center;
}
.section-frontend #weddingTK.weddingTK .downloadSection h4 {
  font-size: 18px;
  margin-top: 1rem;
}
.section-frontend .weddingFooter {
  background-color: #161616;
  padding-top: 4rem;
}
.section-frontend .footerRight {
  color: white;
  padding: 1.75rem 0 2rem;
  border-top: 1px solid #b9b4b4;
  margin-top: 3rem;
}
.section-frontend ul.socialLinks {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.section-frontend ul.socialLinks li {
  width: 42px;
  height: 42px;
  background: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  cursor: pointer;
  transition: all 0.3s ease-out 0.1s;
}
.section-frontend ul.socialLinks li:hover {
  transform: translateY(-5px);
}
.section-frontend ul.socialLinks li img {
  height: 1.25rem;
}
.section-frontend ul.footerLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 2rem;
  color: white;
}
.section-frontend ul.footerLinks li a {
  color: white;
}
.section-frontend ul.footerLinks li a:hover {
  color: #d87f77;
}
.section-frontend ul.contact {
  margin: 0;
  padding: 0;
  list-style: none;
  color: white;
}
.section-frontend ul.contact li {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;
}

/* OVER   RIDING    PREVIOUS    CSS */
.frontEndLogo {
  max-height: 50px;
}
.navbar-nav a {
  color: #333 !important;
}
.sliderFix {
  width: 100%;
  max-width: 96%;
  margin: 0 auto;
}
.sliderFix .slick-slide {
  height: auto !important;
}
.sliderFix .slick-arrow.slick-prev,
.sliderFix .slick-arrow.slick-next {
  opacity: 0;
}
.sliderFix .slick-dots li button:before {
  font-size: 22px;
}
.sliderFix .slick-slide img {
  margin: 0 auto;
}
.ReactTable .rt-thead {
  background-color: #c3924f !important;
}
.ReactTable .rt-table .rt-td a {
  font-weight: 700;
  text-decoration: underline;
}
.css-26l3qy-menu {
  color: #212121;
}
/* IMAGE  EFFECTS */
.side {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  z-index: 10;
  max-height: 420px;
}
.side.left {
  left: 0;
}
.side.left:hover ~ .wrapper .front {
  transform: matrix3d(0.94, 0, 0.34, -0.0002, 0, 1, 0, 0, -0.34, 0, 0.94, 0, 1, 0, 10, 1) translateX(-10px);
}
.side.right {
  right: 0;
}
.side.right:hover ~ .wrapper .front {
  transform: matrix3d(0.94, 0, 0.34, 0.0002, 0, 1, 0, 0, -0.34, 0, 0.94, 0, 1, 0, 10, 1) translateX(30px);
}
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  min-height: auto;
  background-color: transparent;
}
.image {
  perspective: 200px;
  transition: all 0.2s ease;
  background-image: url("../wkt/mosaix.png");
  height: 420px;
  width: 560px;
  background-size: cover;
  max-width: 100%;
}
.navbar-brand img {
  max-width: 160px;
}
.btn-danger {
  background: red;
  border: 1px solid rgb(255, 255, 255);
}

.btn-danger:hover {
  background: #fff;
  border: 1px solid red;
  color: red !important;
}
.css-19qodeu-control,
.css-1pahdxg-control,
.css-19qodeu-control:hover,
.css-1pahdxg-control:hover {
  border-color: #afaaaa !important;
  box-shadow: none !important;
}
.galleryFix {
  max-height: 420px;
  overflow: hidden;
  background-color: #f3f3f3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.galleryFix .react-player{
  max-height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.galleryFix img,
.galleryFix video {
  width: 100%;
  max-height: 420px;
  height: 100%;
  object-fit: contain;
  object-position: center top;
}
.galleryFix:hover img,
.galleryFix:hover video {
  /* transform: scale(1.025); */
  transition: all 0.6s;
  /* object-fit: contain; */
}
/* .galleryFix:hover video{} */
@keyframes zoom {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
@media all and (min-width: 993px) and (max-width: 1199px) {
  .image {
    height: 344px;
  }
}
@media all and (max-width: 992px) {
  html,
  body {
    font-size: 14px;
  }
  .section-frontEnd #header .navbar-brand {
    padding: 5px !important;
  }
  .section-frontend #weddingTK.weddingTK .text4xl {
    font-size: 2.2rem;
  }
  .section-frontend #weddingTK.weddingTK .mainHeading {
    font-size: 22px;
  }
  .hfull {
    height: auto;
  }
  .section-frontend #weddingTK.weddingTK .heroBG {
    background: url(../wkt/banner-mobile.jpg) no-repeat center / cover;
    padding: 2rem 0 20rem;
  }
  h3,
  .h3 {
    font-size: 18px !important;
  }
  .section-frontend #weddingTK.weddingTK section:not(.coupleSaying) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .loginBtn {
    text-align: center;
  }
  .QRCode {
    width: 120px;
    min-width: 120px;
    height: 120px;
  }
  .sharingItem {
    padding: 0.8rem 0.8rem 2.8rem;
  }
  .sharingItem img {
    max-width: 100px;
    max-height: 100px;
  }
  .sharingItem img.sharingPos {
    max-width: 32px;
    right: 2rem;
    bottom: 1rem;
  }
  .QRCode img {
    max-width: 70px;
  }
  .sharingItem h4 {
    margin-top: 0;
  }

  .applications {
    grid-template-columns: repeat(3, 1fr);
  }

  .features .col-md-6 {
    width: 50%;
    max-width: 50%;
    margin-bottom: 1.5rem;
  }
  .features .featureItem figure > img {
    width: 100%;
  }
  .nav.navbar-nav.navbar-right.mainNav {
    width: 100%;
  }
  .navbar-brand {
    margin-left: 15px;
  }
  .sliderSectionWrapper {
    padding-bottom: 10rem;
    overflow-x: auto;
  }
  .sliderOne {
    right: 2rem;
  }
  .sliderSeven {
    left: 2rem;
  }
  .sliderFive {
    right: auto;
    left: 12rem;
  }
  .sliderNine {
    left: 0.7rem;
  }
  .sliderEight {
    left: 27rem;
  }
  .sliderThree {
    right: 13rem;
  }
  .sliderFour {
    right: auto;
    left: 15rem;
  }
  .sliderSix {
    left: 26rem;
  }
}
@media all and (max-width: 640px) {
  .py-5r {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sliderSection {
    background-size: auto;
  }
  .navbar-header {
    padding-left: 15px;
  }
  .features .featureItem {
    border-radius: 8rem 5px 2rem 2rem;
    height: auto;
    margin-bottom: 1.5rem;
  }
  .features .col-md-6 {
    width: 100%;
    max-width: 100%;
  }
  .applications {
    grid-template-columns: repeat(1, 1fr);
  }
  .weddingFooter .col-md-4 {
    margin-bottom: 1.5rem;
  }
  .footerRight {
    text-align: center;
  }
  .footerLinks {
    justify-content: center;
    margin-top: 1rem;
  }
  .heroBG h1 {
    text-align: center;
  }
  .heroBG a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .sliderFour .testimonial {
    right: auto;
    left: 1.5rem;
    border-radius: 0.2rem 2rem 2rem 2rem;
  }
  .sliderFive .testimonial {
    right: auto;
    left: 1.5rem;
    border-radius: 0.2rem 2rem 2rem 2rem;
  }
  .image {
    height: 230px;
  }
  .sliderSix img,
  .sliderEight img {
    width: 100px;
    max-width: 100px !important;
  }
  .sliderTwo {
    top: 14rem;
  }
  .sliderThree .testimonial {
    right: auto;
  }
  .downloadSection {
    flex-direction: column;
  }
  .section-frontend #weddingTK.weddingTK .downloadSection h4 {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  .section-frontend #weddingTK.weddingTK .mainHeading {
    text-align: center;
    margin-bottom: 0.75rem;
  }
}
